@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Thin'), url('../public/fonts/Poppins-Thin.ttf');
    font-weight: 100;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins ExtraLight'), url('../public/fonts/Poppins-ExtraLight.ttf');
    font-weight: 200;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Light'), url('../public/fonts/Poppins-Light.ttf');
    font-weight: 300;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Regular'), url('../public/fonts/Poppins-Regular.ttf');
    font-weight: 400;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Medium'), url('../public/fonts/Poppins-Medium.ttf');
    font-weight: 500;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins SemiBold'), url('../public/fonts/Poppins-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Bold'), url('../public/fonts/Poppins-Bold.ttf');
    font-weight: 700;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins ExtraBold'), url('../public/fonts/Poppins-ExtraBold.ttf');
    font-weight: 800;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Black'), url('../public/fonts/Poppins-Black.ttf');
    font-weight: 900;
    font-display: swap;
  }

  :root {
    --banner-bg: rgb(14, 9, 30);
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins';
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox #cd3131*/
  --toastify-font-family: 'Poppins' !important;
}

::-webkit-scrollbar {
  display: none;
}

input {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.main-background {
  background: linear-gradient(169.53deg, #f4de53 14.12%, #d2ad2e 69.31%, #c0941a 104.63%, #bd8f17 135.53%, #b5810d 170.85%, #b07908 186.31%);
}

.myborder-block {
  border: 1px solid transparent;
  border-image: linear-gradient(to right, #f4de53 0%, #b17b09 99%, #b17b09 100%);
  border-image-slice: 1;
  /* box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.14); */
}
.altnav-container {
  @apply container flex w-full items-center justify-around overflow-x-scroll;
}

.main-container {
  @apply flex w-full items-center justify-center px-2 sm:px-4 xl:container;
}

.product-out-container {
  @apply grid w-full animate-fade-in-down grid-cols-2 gap-2 sm:gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5;
}

.competition-out-container {
  @apply grid w-full animate-fade-in-down grid-cols-2 gap-2 md:grid-cols-3 md:gap-4;
}

.item-container {
  @apply grid w-full animate-fade-in-down grid-cols-1 gap-x-2 gap-y-5 md:grid-cols-3;
}
.related-product-container {
  @apply grid w-full animate-fade-in-down grid-cols-2 gap-2 md:grid-cols-4 md:gap-4;
}

.product-out-container .slick-active .product-container {
  @apply scale-100 opacity-100;
}

.btn-primary {
  @apply rounded bg-blue-rgba6 font-semibold hover:bg-blue-rgba1 disabled:bg-light-grey disabled:text-dark-grey;
}

.btn-disabled {
  @apply bg-light-grey font-semibold text-dark-grey;
}

.banner-height {
  @apply h-full lg:h-[304px];
}

.sub-slider-height {
  @apply h-24 w-full xs:h-32 sm:h-48 md:h-56 lg:h-full;
}

.altnav-height {
  @apply h-[95px];
}

.banner-text-shadow {
  text-shadow: 0 0 48px #000, 0 0 6px #000, 1px -2px 20px #000;
}

.ticket-shadow {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.price-main {
  @apply rounded px-3 py-2 text-sm;
}

.price-sale {
  @apply rounded px-3 py-2 text-sm font-semibold;
}

.product-container {
  @apply flex cursor-pointer flex-col rounded-sm;
}

.blue-divider {
  @apply my-4 h-[1px] w-full bg-blue-rgba1;
}

@layer components {
  .web-info {
    background: linear-gradient(182.68deg, #f4de53 0.2%, #d2ad2e 44.44%, #c0941a 72.75%, #bd8f17 97.52%, #b5810d 125.83%, #b07908 138.22%);
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.25));
  }
}

.plan-input {
  @apply appearance-none border-0 border-b border-solid border-black bg-transparent py-2 px-3 focus:border-transparent focus:outline-none focus:ring-1 focus:ring-grey;
}

.page-title {
  @apply w-full py-4 text-lg font-bold md:py-6 md:text-xl;
}

.time-left-bg {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
}

.btn-cart {
  @apply cursor-pointer rounded border border-grey px-1 text-black disabled:text-grey;
}

.btn-white {
  @apply rounded bg-blue-rgba10 text-sm hover:bg-blue-rgba8 hover:text-white disabled:border-transparent disabled:bg-light-grey disabled:text-dark-grey md:text-base;
}

.btn-transparent {
  @apply rounded border border-blue-rgba1 bg-transparent text-sm hover:bg-blue-rgba1 hover:text-white disabled:border-transparent disabled:bg-light-grey disabled:text-dark-grey md:text-base;
}

.btn-join-now {
  @apply rounded border border-blue-rgba1 bg-blue-rgba1 text-sm hover:bg-yellow-hex3 disabled:border-transparent disabled:bg-light-grey disabled:text-dark-grey md:text-base;
}

.btn-pay {
  @apply rounded bg-blue-rgba1 text-base font-semibold text-white hover:bg-blue-rgba6 disabled:bg-light-grey disabled:text-dark-grey;
}

.btn-qna {
  @apply rounded-sm border-solid border-blue-rgba1 py-3 px-4 text-left text-sm hover:border-0 hover:bg-blue-rgba1 hover:text-white md:text-center;
}

.card-container {
  @apply rounded bg-blue-rgba2 text-white shadow-[0_2px_6px_1px_rgba(0,0,0,0.14)];
}

.table-container {
  @apply grid grid-flow-row auto-rows-max divide-y divide-blue-rgba6 rounded border border-blue-rgba6;
}

.table-row {
  @apply flex items-center py-4 text-center text-xs md:text-sm;
}

.page-arrow {
  @apply text-white hover:stroke-2 hover:font-semibold hover:text-blue-rgba1 disabled:text-grey3;
}

.slider-arrow {
  @apply absolute top-1/2 z-20 text-white hover:stroke-2 hover:font-semibold hover:text-blue-rgba1 disabled:text-grey;
}

/* Needed, because react-slick adds style in the slider wrapper which can only be override by using !important */
.fullscreen-slider-wrap {
  @apply flex min-w-full justify-center !important;
}

.fullscreen-slider .iiz__img {
  max-height: calc(100vh - 96px);
}

.fullscreen-slider,
.fullscreen-slider .slick-track {
  @apply flex items-center;
}

.fullscreen-slider .slick-next,
.fullscreen-slider .slick-prev {
  @apply z-10;
}

.fullscreen-slider .slick-slider {
  @apply min-w-[100vw];
}

.sold-out {
  @apply origin-center rotate-[30deg] rounded bg-error p-4 text-2xl font-semibold uppercase text-white;
}

.sold-out-container {
  @apply absolute top-0 left-0 z-10 h-full w-full bg-black opacity-40;
}

.collapse {
  overflow: hidden;
  transition: 0.3s max-height ease-out;
}
.banner-slider .slick-slide > div:first-child,
.banner-slider .slick-list,
.banner-slider .slick-track,
.banner-slider .slick-slide {
  @apply h-full;
}

.nav-icon {
  @apply h-6 w-6 md:h-7 md:w-7;
}
